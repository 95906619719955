@font-face {
    font-family: "Avenir Next";
    src: url("../public/fonts/AvenirNextLTPro-Regular.otf");
}
@font-face {
    font-family: "Avenir Next";
    src: url("../public/fonts/AvenirNextLTPro-Bold.otf");
    font-weight: bold;
}
@font-face {
    font-family: "Avenir Next";
    src: url("../public/fonts/AvenirNextLTPro-It.otf");
    font-style: italic, oblique;
}

body {
	background-color: #E5E5E5 !important;
	font-family: "Avenir Next", sans-serif !important;
	display: flex;
    flex-direction: column;
}