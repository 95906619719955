#genres-container {
  font-size: 1.1rem;
}

#audio-player {
  z-index: 1000;
}

#player-info {
  position: absolute;
  top: -40px;
  width: 115px;
  height: 115px;
  margin-left: 40px;
  transform: rotateZ(0);
  transition: 0.3s ease all;
  box-shadow: 0 0 0 10px #fff;
  border-radius: 50%;
  overflow: hidden;
}

.buy {
  background-color: yellow;
  color:#222 !important;
}



/*******************************************/

