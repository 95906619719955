.background-black {
    background-color: #000 !important;
}

.error-font {
    line-height: 27px !important;
    font-weight: 700 !important;
}

.error-font h4 {
    font-size: 24px !important;
    line-height: 36px !important;
    font-weight: 700 !important;
}
.error-font h6 {
    font-size: 18px !important;
    line-height: 27px !important;
    font-weight: 700 !important;
}

.btn-error {
    width: 180px !important;
	height: 40px;
	border-radius: 100px !important;
	display: block !important;
	background-color: #5EC868 !important;
    color: #fff !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 17px !important;
	padding: 12px 0px !important;
	text-align: center !important;
}