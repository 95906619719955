/* Set the background color of body to tan */
body {
  font-size: 1rem;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  body {
    font-size: 1.2rem;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  body {
    font-size: 1.4rem;
  }
}